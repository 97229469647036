<template>
  <base-view title="Mis Documentos" icon="columns">
    <div>
      <check-authorization :requiresAuthorizations="['inversionistas indice documentos']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Documentos

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Form -->
              <documentos-inversionista-form
                :value="showForm || isEditingResource"
                @input="showForm = $event"
              />
              <!-- Search -->
              <search-bar
                placeholder="Buscar por inversionista, documento o nombre de archivo"
                @filter="(filter) => this.filter = filter"></search-bar>
              <!-- Options -->
              <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getUsers(null)">
                    Recargar
                  </a>
                </div>
              </div>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <documentos-inversionista-list @on-got-item="showForm = true" :filter="filter"></documentos-inversionista-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import DocumentosInversionistaForm from '@/components/Inversionistas/Documentos/DocumentosInversionistaForm'
import DocumentosInversionistaList from '@/components/Inversionistas/Documentos/DocumentosInversionistaList'
import { mapActions, mapGetters } from 'vuex'
import SearchBar from '../../components/Common/Views/SearchBar.vue'

const STORE_MODULE = 'documentosInversionistaModule'

export default {
  name: 'DocumentosInversionistasView',

  components: {
    DocumentosInversionistaForm,
    DocumentosInversionistaList,
    SearchBar
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      filter: ''
    }
  },

  computed: mapGetters(STORE_MODULE, ['isEditingResource']),

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>

<style scoped>

</style>
